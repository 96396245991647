import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Message";
import FormContainer from "../components/FormContainer";
import OpenAI from "openai";
import {
  listProductsDetails,
  updateProduct,
  uploadImages,
} from "../actions/productAction";
import {
  PRODUCT_UPDATE_RESET,
  UPLOAD_IMAGES_RESET,
} from "../constants/productConstant";
import CategoryDropdown from "../components/UI/CategoryDropdown";
import "./productDetails.css";

const initialProductState = {
  name: "",
  price: 0,
  oldPrice: 0,
  thumbnail: ["", "", "", ""],
  brand: "",
  category: "",
  quantity: 0,
  description: "",
};

const ProductEditScreen = () => {
  const { id } = useParams();
  const [productState, setProductState] = useState(initialProductState);
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [html, setHtml] = useState("");
  const [addedImages, setAddedImages] = useState([]);

  const [image, setImage] = useState("");
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const productDetails = useSelector((state) => state.productDetails);
  const { error, loading, product } = productDetails;

  const imageUploadedUrls = useSelector((state) => state.uploadImagesToDb);
  const { error: uploadError, loading: uploadloading, images: imageUploaded } = imageUploadedUrls;

  const productUpdate = useSelector((state) => state.productUpdate);
  const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = productUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET });
      dispatch({ type: UPLOAD_IMAGES_RESET });
      navigate("/admin/productlist");
    }
  }, [navigate, successUpdate, dispatch]);

  useEffect(() => {
    if (!product || product._id !== id) {
      dispatch(listProductsDetails(id));
    } else {
      setHtml(product.description);

      setProductState({
        name: product.name,
        price: product.price,
        oldPrice: product.oldPrice,
        thumbnail: product.thumbnail,
        brand: product.brand,
        category: product.category,
        quantity: product.quantity,
        description: product.description,
      });

      // Set individual image states
      if (product.thumbnail) {
        setImage(product.thumbnail[0] || "");
        setImage1(product.thumbnail[1] || "");
        setImage2(product.thumbnail[2] || "");
        setImage3(product.thumbnail[3] || "");
      }
    }
  }, [dispatch, product, id]);

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      if (images.length) {
        const formData = new FormData();
        formData.append("productId", id);
        images.forEach((image) => {
          formData.append("photo", image);
        });

        dispatch(uploadImages(formData));
      } else {
        dispatch(updateProduct({ ...productState, _id: id }));
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      // Handle error as needed
    }
  };

  useEffect(() => {
    if (Array.isArray(imageUploaded) && imageUploaded.length > 0) {
      let updatedThumbnail = [...imageUploaded];
      if (updatedThumbnail.length > 4) {
        updatedThumbnail = updatedThumbnail.slice(0, 4); // Take only the first 4 images
      }
      dispatch(updateProduct({ ...productState, _id: id, thumbnail: updatedThumbnail }));
     
    }
  }, [dispatch, id, imageUploaded, productState]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      setImages((prevImages) => [...prevImages, ...files]);
    }
  };

  const convertTextToHtml = async () => {
    const apiKey = "sk-proj-jsOVITOt5dI0DXevuLfRT3BlbkFJxMkfnxKCwMBarKkp1Ss4";
    const openai = new OpenAI({ apiKey, dangerouslyAllowBrowser: true });

    const response = await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "user",
          content: `give me a detail description in arabic about ${productState.name}, the output should be inside two html div with styling the text and make text direction from right to left`,
        },
      ],
    });

    const desc = response.choices[0].message.content;
    setHtml(desc);
    setProductState((prevState) => ({ ...prevState, description: desc }));
  };

  useEffect(() => {
    convertTextToHtml();
  }, [productState.name]);

  return (
    <div>
      <FormContainer>
        <h1>Edit Product</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={productState.name}
                onChange={(e) => setProductState({ ...productState, name: e.target.value })}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="price">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter price"
                value={productState.price}
                onChange={(e) => setProductState({ ...productState, price: e.target.value })}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="oldPrice">
              <Form.Label>Old Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter old price"
                value={productState.oldPrice}
                onChange={(e) => setProductState({ ...productState, oldPrice: e.target.value })}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="image">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter image"
                value={image}
                onChange={(e) => setImage(e.target.value)}
              ></Form.Control>
              <Form.Control
                type="file"
                label="Choose File"
                onChange={handleImageChange}
              ></Form.Control>
              {uploading && <Loader />}
            </Form.Group>

            <Form.Group controlId="image1">
              <Form.Label>Image1</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter image"
                value={image1}
                onChange={(e) => setImage1(e.target.value)}
              ></Form.Control>
              <Form.Control
                type="file"
                label="Choose File"
                onChange={handleImageChange}
              ></Form.Control>
              {uploading && <Loader />}
            </Form.Group>

            <Form.Group controlId="image2">
              <Form.Label>Image2</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter image"
                value={image2}
                onChange={(e) => setImage2(e.target.value)}
              ></Form.Control>
              <Form.Control
                type="file"
                label="Choose File"
                onChange={handleImageChange}
              ></Form.Control>
              {uploading && <Loader />}
            </Form.Group>

            <Form.Group controlId="image3">
              <Form.Label>Image3</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter image"
                value={image3}
                onChange={(e) => setImage3(e.target.value)}
              ></Form.Control>
              <Form.Control
                type="file"
                label="Choose File"
                onChange={handleImageChange}
              ></Form.Control>
              {uploading && <Loader />}
            </Form.Group>

            <Form.Group controlId="brand">
              <Form.Label>Brand</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter brand"
                value={productState.brand}
                onChange={(e) => setProductState({ ...productState, brand: e.target.value })}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="countInStock">
              <Form.Label>Stock</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter stock"
              
                value={productState.quantity}
                onChange={(e) => setProductState({ ...productState, quantity: e.target.value })}
              ></Form.Control>
            </Form.Group>

            <CategoryDropdown
              category={productState.category}
              setCategory={(category) => setProductState({ ...productState, category })}
            />

            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter description"
                value={productState.description}
                onChange={(e) => setProductState({ ...productState, description: e.target.value })}
              ></Form.Control>
            </Form.Group>

            <Button type="submit" variant="primary">
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </div>
  );
};

export default ProductEditScreen;
